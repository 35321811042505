
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormHelperText, Container, Typography, Checkbox, FormControlLabel, ListItem, ListItemText, Paper, List, Card, CardContent, Grid, Divider, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useSelector } from "react-redux";
import { dispatch } from "../store";
import { getIntSpeechToken, getInterviewDetails, getInterviewTokens, getQuestionVoice, resetAllInterviewState, setCommonErrors, setCurrentMeetingId } from "../store/slices/interviewsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Info, CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import logo from './../assets/logo_cropped.svg';
import { preProcessInterviewData } from "../utils";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaPermissionsCheck from '../components/hooks/useMediaPermissionsCheck'
import ErrorDialog from "../components/ErrorDialog";
import './../assets/app.css'
import { clearDB, clearTable, getQuestionByType, safelyDeleteDatabase, updateQuestionBlobById } from "../store/doxie/doxieDb";
import useScreenshotUploader from "../components/hooks/useScreenshotUploader";

export default function InitiateMeeting() {
    const AUDIO_DBNAME = "AudioDatabase";
    const AUDIO_DBVERSION = 3;
    const [expanded, setExpanded] = React.useState(false);
    const [hasMediaDevices, setHasMediaDevices] = useState(false);
    const { isLoading, interviewsData, interviewTokens, error } = useSelector((state) => state.interviews);
    // const [isLoading, setIsLoading] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isApiCalled, setIsApiCalled] = useState(false);
    const [isProceedEnabled, setProceedEnabled] = useState(false);
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);

    const [parameterValue, setParameterValue] = useState("");

    const [isAgreed, setIsAgreed] = useState(false);
    const [isInterviewReady, setIsInterviewReady] = useState(false);
    const { captureRef, startScreenshotCapture, manualCapture } = useScreenshotUploader(10000, 1280, 720, "Init");
    // useMediaPermissionsCheck('/mediapermissiondenied');

    useEffect(() => {
        const cleanup = startScreenshotCapture();
        return cleanup;
    }, [startScreenshotCapture]);

    const handleAgreementChange = (event) => {
        setIsAgreed(event.target.checked);
        if (!error) {
            setProceedEnabled(!isProceedEnabled);
        }
    };

    const checkMediaDevices = async () => {
        try {
            // Check if the devices are available
            const devices = await navigator.mediaDevices.enumerateDevices();
            const hasVideoInput = devices.some(device => device.kind === 'videoinput');
            const hasAudioInput = devices.some(device => device.kind === 'audioinput');

            if (!hasVideoInput || !hasAudioInput) {
                handleError("Video or audio device not found! Please join interview with a active video and audio device.")
                return;
            }

            // Request permissions for video and audio
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            stream.getTracks().forEach(track => track.stop());
            // If this point is reached, permissions have been granted
            setHasMediaDevices(true);
        } catch (error) {
            setHasMediaDevices(false);
            handleError("Error accessing media devices or permissions not granted")
        }
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleProceedClick = () => {
        if (isAgreed) {
            if (hasMediaDevices) {
                navigate("/meeting");
            }
            else {
                handleError("Please enable Audio & Video before joining the interview.");
            }
        } else {
            handleError("Please agree to the terms and conditions to proceed.");
        }
    };

    useEffect(() => {
        dispatch(resetAllInterviewState());
        clearAudioChunks();
        clearTable();
    }, []);

    useEffect(() => {
        const value = params.get("meeting_id");
        setParameterValue(value);
    }, []);

    // console.log("interviewdata", inte)
    useEffect(() => {
        if (parameterValue) {
            // setIsApiCalled(false);
            dispatch(getInterviewTokens(parameterValue));
            // dispatch(getIntSpeechToken());
            // setTimeout(() => {
            //     setIsApiCalled(true);
            // }, 1000);
        }
    }, [parameterValue]);

    useEffect(() => {
        if (interviewTokens?.int_details?.meta?.ivid) {
            dispatch(setCurrentMeetingId(interviewTokens?.int_details?.meta?.ivid));
            dispatch(getInterviewDetails(interviewTokens?.int_details?.meta?.ivid));
        }
    }, [interviewTokens]);

    useEffect(() => {
        if (interviewsData && interviewsData.length > 0) {
            if (interviewsData[0]?.meta) {
                try {
                    checkMediaDevices();
                    let json_Questions = preProcessInterviewData(interviewsData[0]?.meta);
                    getQuestionByType("welcome_greeting").then(async results => {
                        if (results) {
                            let blob = await dispatch(getQuestionVoice(results.question));
                            await updateQuestionBlobById(results.id, blob);
                        }
                    }).catch(error => {
                        throw new Error("Interview is not ready!!. Please reach to customer support. " + error)
                    })

                    if (json_Questions) {
                        setIsInterviewReady(true);
                    }
                    else {
                        throw new Error("Interview is not ready!!. Please reach to customer support. ")
                    }
                }
                catch (error) {
                    handleError("Error in application, " + error)
                }
            }
            else {
                dispatch(setCommonErrors("Job data or meta data invalid/unavailable "))
                navigate("/meetingerror");
            }
        }
    }, [interviewsData])

    // Example function that could trigger the error dialog
    const handleError = (message) => {
        setErrorMessage(message);
        setErrorOpen(true);
    };

    const handleClose = () => {
        setErrorOpen(false);
    };

    const handleRefresh = () => {
        window.location.reload();
    };

    function clearAudioChunks() {
        const request = indexedDB.open(AUDIO_DBNAME, AUDIO_DBVERSION);

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('audioChunks')) {
                db.createObjectStore("audioChunks", { keyPath: "id" });
            }
        };

        request.onerror = function (event) {
            console.error("Error opening the database for clearing:", event);
        };

        request.onsuccess = function (event) {
            const db = event.target.result;
            const transaction = db.transaction(["audioChunks"], "readwrite");
            const store = transaction.objectStore("audioChunks");

            // Clear all records in the store
            const clearRequest = store.clear();

            clearRequest.onsuccess = function () {
                console.log("Audio store cleared successfully.");
            };

            clearRequest.onerror = function () {
                console.error("Failed to clear AudioChunks store:", clearRequest.error);
            };
        };
    }
    // console.log("interviewTokens", isInterviewReady, interviewTokens, interviewsData, isApiCalled)
    return (
        <div ref={captureRef} style={{ padding: "1px", backgroundColor: "lightgrey" }}>
            <div>
                {isLoading && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="100vh"
                    >
                        <CircularProgress size={36} />
                        <Box mt={1} />
                        <Typography variant="subtitle2">Preparing interview.. please wait..</Typography>
                    </Box>
                )}
                {error && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight="100vh">
                        <Box sx={{ mt: 5 }} >
                            <Typography variant="h5" color="red" >{error?.message || "Internal server error!!"}!</Typography>
                            <br />
                            <Typography variant="h6" >Contact our Support incase you think this is an issue.</Typography>
                            <br />
                            <Button variant="contained" href="https://help.novelhire.com/portal/en/signin" target="_blank" >Contact Support</Button>
                        </Box>
                    </Box>
                )}
                {isInterviewReady &&
                    <Container maxWidth="false" className="containerStyle">
                        <Box maxWidth="md">
                            <Paper elevation={4} style={paperStyle}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item xs={12} sm={3} textAlign="center">
                                        <img src={logo} width='90px' alt="NovelHire" />
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Typography variant="h4" gutterBottom style={titleStyle}>
                                            Welcome to NovelHire Interviewer
                                        </Typography>
                                        <Typography variant="subtitle1" style={subtitleStyle}>
                                            INTERVIEWS REIMAGINED
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Card elevation={3} style={{ marginBottom: '2rem', backgroundColor: 'rgba(225, 245, 254, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <CardContent>
                                    {/* <Box display="flex" alignItems="center" marginBottom="1rem">
                                <Info style={{ color: '#12c2e9', marginRight: '8px' }} />
                                <Typography variant="h6" color="textSecondary" fontFamily="poppins">Details</Typography>
                            </Box>
                            <Divider style={{ marginBottom: '1rem' }} /> */}
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontFamily="poppins">Job Title</Typography>
                                            {interviewsData &&
                                                <Typography variant="h6" fontWeight='bold' fontFamily="poppins">{interviewsData[0]?.title ? interviewsData[0]?.title : interviewsData[0]?.Job_Title_Cand}</Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontFamily="poppins">Organization</Typography>
                                            {interviewsData &&
                                                <Typography variant="h6" fontWeight='bold' fontFamily="poppins">{interviewsData[0]?.Company ? interviewsData[0]?.Company : "Not Available"}</Typography>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontFamily="poppins">Candidate Name</Typography>
                                            <Typography variant="h6" fontWeight='bold' fontFamily="poppins">{interviewsData ? interviewsData[0]?.cand_name : "Not Available"}</Typography>
                                        </Grid>
                                        {interviewsData &&
                                            <Grid item xs={12} sm={12}>
                                                {interviewsData[0]?.jd &&
                                                    <Accordion expanded={expanded} onChange={handleChange('panel1')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header">
                                                            <Typography sx={{ width: '33%', flexShrink: 0 }} fontSize='16px' fontFamily="poppins">
                                                                Job Description
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography sx={{ color: 'text.primary' }} fontFamily="poppins">{interviewsData ? interviewsData[0]?.jd : ""}</Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </CardContent>
                            </Card>

                            <Box style={{ padding: '1rem', backgroundColor: '#E1F5FE', marginBottom: '2rem' }}>
                                <Box display="flex" alignItems="center" marginBottom="1rem">
                                    {/* <CheckBoxOutlineBlank style={{ color: '#c471ed', marginRight: '8px' }} /> */}
                                    <Typography variant="h6" color="textSecondary" fontFamily="poppins">Instructions & Guidelines</Typography>
                                </Box>
                                <Box style={{ maxHeight: '500px', overflowY: 'auto', backgroundColor: '#ffffff' }}>
                                    <Typography variant="h6" gutterBottom paddingLeft='1rem' margin='0px' paddingTop='1rem' fontFamily="poppins">
                                        <b>Pre-Interview</b>
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemText><p style={{ color: "#FF7043", fontFamily: 'poppins', margin: '0px' }} >1.  <span style={{ fontWeight: "bolder", color: "red", fontSize: '20px' }}>Important:</span>  Hello, I am your <b> NovelHire interviewer </b>. I will guide you through the interview process. During the interview process, i will ask questions to assess your knowledge and skills for the role. Once you have answered the question, please say <span style={{ fontWeight: "bolder", color: "red", fontSize: '20px' }}>'I am done'</span> to let me know that you have completed your answer. Thank You. Happy Interviwing! </p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>2. <b>Equipment Check:</b> Confirm that your camera and microphone are functioning correctly. Use our media validation link here to <a href='/devicetest' target="_blank">test your video and audio setup</a>.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>3. <b>Ready to Start</b>: Click the 'Start Interview' button only when you are fully prepared for the interview.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>4. <b>Timely Start</b>: Begin the interview on time. Significant delays can cause the interview to expire, counting as an attempt.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>5. <b>No Refresh or Close</b>: Once the interview starts, do not refresh or close the window. This will terminate the interview and all progress will be lost.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>6. <b>Avoid Malpractices</b>: Refrain from any cheating or fraudulent behavior during the interview. Such actions will invalidate the interview and will be reported to the hiring company.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>7. <b>Support and Demo</b>: For any doubts or technical issues, refer to our interviewer demo available <a href='https://novelhire.com/' target="_blank">here</a> and contact our <a href='https://novelhire.com/help' target="_blank">support</a> team for assistance.</p></ListItemText>
                                        </ListItem>
                                    </List>
                                    <Typography variant="h6" gutterBottom paddingLeft='1rem' margin='0px' paddingTop='1rem' fontFamily="poppins">
                                        <b>In-Interview</b>
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>1. <b>Maintain Audio/Video</b>: Keep your video and audio on at all times. Turning off either will end the interview and mark your attempt as unsuccessful.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>2. <b>Professional Conduct</b>: Stay professional and attentive, with appropriate attire and a focus on the interview.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>3. <b>Stable Internet Connection</b>: Ensure your internet connection is stable. Inform the interviewer immediately if you encounter connectivity issues.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>4. <b>Quiet Environment</b>: Choose a quiet, distraction-free environment to maintain focus and professionalism.</p></ListItemText>
                                        </ListItem>
                                    </List>
                                    <Typography variant="h6" gutterBottom paddingLeft='1rem' margin='0px' paddingTop='1rem' fontFamily="poppins">
                                        <b>Post-Interview</b>
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>1. <b>Ending the Interview</b>: Use the 'End Interview' button to officially conclude the interview.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>2. <b>Post-Interview Wait</b>: Do not close the interview window immediately. Wait until the browser indicates it's safe to close, ensuring all data is synced with our server.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>3. <b>Feedback and Queries</b>: Contact our <a href='https://novelhire.com/help' target="_blank">support</a> team for feedback or queries.</p></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText><p style={{ fontFamily: 'poppins', margin: '0px' }}>4. <b>Follow-Up</b>: Await follow-up communications from our team regarding next steps or additional information.</p></ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                            <Box style={{ padding: '1rem', backgroundColor: '#E1F5FE', marginBottom: '2rem' }}>
                                <FormControlLabel
                                    control={<Checkbox icon={<CheckBoxOutlineBlank />} checkedIcon={<CheckBox />} checked={isAgreed} onChange={handleAgreementChange} color="primary" />}
                                    label="I have read and agree to the Terms & guidelines"
                                    style={{ display: 'block', marginBottom: '1rem', color: 'ActiveBorder', fontFamily: 'poppins' }}
                                />
                                <Button variant="contained" style={isProceedEnabled ? startInterviewButtonStyle : startInterviewButtonDisabledStyle} disabled={!isProceedEnabled} fullWidth onClick={handleProceedClick}>
                                    Start Interview
                                </Button>
                            </Box>
                            <ErrorDialog
                                open={errorOpen}
                                handleClose={handleClose}
                                handleRefresh={handleRefresh}
                                errorMessage={errorMessage}
                            />
                        </Box>
                    </Container>
                }
                <Box style={{ padding: '1rem', }}>
                    <Typography variant="subtitle2" gutterBottom paddingLeft='1rem' margin='0px' paddingTop='1rem' fontFamily="poppins">
                        {process.env.REACT_APP_VERSION}
                    </Typography>
                </Box>
            </div>
        </div >
    );
}

const paperStyle = {
    padding: '2rem',
    marginBottom: '2rem',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '15px',
};

const cardStyle = {
    marginBottom: '2rem',
    backgroundColor: 'rgba(225, 245, 254, 0.8)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const titleStyle = {
    color: '#2C3E50', // dark navy blue
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
};

const subtitleStyle = {
    color: '#2C3E50', // soft grey
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'normal',
};

const startInterviewButtonStyle = {
    backgroundColor: '#f64f59', // A color from the gradient
    color: 'white', // White text for contrast
    fontFamily: 'Poppins', // Consistent font family
    fontSize: '1rem', // Appropriate font size
    fontWeight: '600', // Slightly bold
    textTransform: 'none', // Normal casing, no uppercase
    padding: '10px 20px', // Comfortable padding
    margin: '20px 0', // Spacing from other elements
    borderRadius: '8px', // Slightly rounded corners
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    border: 'none', // No border
    cursor: 'pointer', // Pointer cursor on hover
    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
    width: '100%', // Full width for mobile responsiveness
    // Other properties for focus, active, etc. can be added here
};

const startInterviewButtonDisabledStyle = {
    ...startInterviewButtonStyle,
    backgroundColor: '#cccccc', // Greyed out background
    color: '#666666', // Dimmed text color
    cursor: 'default', // Default cursor to indicate non-clickable
    boxShadow: 'none', // No shadow to make the button appear "flat"
};