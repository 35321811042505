import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";


export default function MeetingWelcomeDialog({ isModalOpen, handleInitWarningModelClick }) {

    return (
        <>
            <Dialog
                open={isModalOpen}
                onClose={() => { }}  // Empty function to ensure modal can't be closed by default actions
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        borderRadius: '15px',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                    }
                }}
            >
                <DialogContent>
                    <Typography variant="h5" gutterBottom>
                        Welcome to NovelHire!
                    </Typography>
                    <Typography variant="body1">
                        Please make sure your microphone and camera are set up correctly before joining.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInitWarningModelClick}  // Close the modal when the button is clicked
                    >
                        Start
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}