import CryptoJS from 'crypto-js';
import { storeAudioBlob } from '../store/doxie/doxieDb';

const noOfQuestions = 1;
let totalQuestions = 0;
const numberingPattern = /^\s*\d+\.\s*/;

function encrypt(text) {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_INTERNAL_ENCRY_KEY);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    const ivHex = CryptoJS.enc.Hex.stringify(iv);
    const encryptedDataHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex);

    return { iv: ivHex, encryptedData: encryptedDataHex };
}

function decrypt(encryptedDataHex, ivHex) {
    const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_INTERNAL_ENCRY_KEY);
    const iv = CryptoJS.enc.Hex.parse(ivHex);
    const encryptedData = CryptoJS.enc.Hex.parse(encryptedDataHex);

    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedData);

    const decryptedData = CryptoJS.AES.decrypt(encryptedBase64, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decryptedData.toString(CryptoJS.enc.Utf8);
}

function decryptData(encryptedData) {
    try {
        const splitValues = encryptedData.split("::");
        const parsedData = {
            iv: splitValues[1],
            encryptedData: splitValues[0]
        };
        return decrypt(parsedData.encryptedData, parsedData.iv);
    } catch (error) {
        console.error('Decryption failed:', error.message);
        return null;
    }
}

function preProcessInterviewData(meta) {
    let questionsDict = {};
    try {
        // console.log("preProcessInterviewData", meta);
        let final_interview_data = decryptData(meta);
        final_interview_data = JSON.parse(final_interview_data);
        let data = JSON.parse(final_interview_data);
        if (!Array.isArray(data)) {
            data = Object.entries(data);
        }
        // Adding 'general' category for 'other' questions
        questionsDict["general"] = [];
        questionsDict["coding"] = [];
        let greetings_object = {
            "welcome": '',
            "closing": ''
        };
        // Iterate over each category in the data
        data.forEach(([key, questions]) => {
            if (!['welcome_greeting', 'interview_closing_message', 'other', 'simple_coding_questions'].includes(key.toLowerCase())) {
                questionsDict[key] = [];
            }
            var category = categorize(key.toLowerCase());
            category = category || key.toLowerCase();
            // console.log("category", category, questionsDict)
            let final_question, final_category;
            questions.forEach((question, index) => {
                const cleanedQuestion = question.replace(/,\s*(?=[}\]])/g, '');
                let isAsked = 'false';
                // const cleanedQuestion = question; // Assuming numberingPattern is [\d.]
                switch (category) {
                    case 'welcome_greeting':
                        greetings_object["welcome"] = cleanedQuestion;
                        final_question = cleanedQuestion;
                        isAsked = 'false';
                        break;
                    case 'interview_closing_message':
                        greetings_object["closing"] = cleanedQuestion;
                        final_question = cleanedQuestion;
                        isAsked = 'false';
                        break;
                    case 'coding questions':
                        let coding_prefix = index === 0 ? "Ok, now let's start with some coding questions! " : "";
                        questionsDict["coding"].push(coding_prefix + cleanedQuestion);
                        final_question = coding_prefix + cleanedQuestion;
                        isAsked = 'false';
                        break;
                    case 'other':
                        let prefix = index === 0 ? "Ok, now let's start with some general questions! " : "";
                        questionsDict["general"].push(prefix + cleanedQuestion);
                        final_question = prefix + cleanedQuestion;
                        isAsked = 'true';
                        break;
                    default:
                        let categoryPrefix = index === 0 ? "Ok, now let's start with some " + key + " questions! " : "";
                        questionsDict[key].push(categoryPrefix + cleanedQuestion);
                        final_question = categoryPrefix + cleanedQuestion;
                        isAsked = 'true';
                        break;
                }
                storeAudioBlob(category, null, final_question, "false");
            });
        });
        const filteredData = Object.fromEntries(
            Object.entries(questionsDict).filter(([key, value]) => value.length > 0)
        );
        let final_dict = {
            "questions": filteredData,
            "greetings": greetings_object
        };
        // console.log("preProcessInterviewData 1", final_dict);
        return final_dict;
    }
    catch (error) {
        console.error('preProcessInterviewData:', error);
        return null;
    }
}

function getTotalQuestions(questionsObject) {
    let totalQuestions = 0;

    // Iterate over each key in the object and add the length of the array to the total
    for (const key in questionsObject) {
        if (questionsObject.hasOwnProperty(key)) {
            totalQuestions += questionsObject[key].length;
        }
    }
    return totalQuestions;
}

function categorize(string) {
    if (string.includes('welcome')) {
        return 'welcome_greeting';
    }
    if (string.includes('closing')) {
        return 'interview_closing_message';
    }
}


//Deprecated
function preProcessInterviewData_old(interviewsData) {
    let questionsDict = {};
    const questionsDictionary = {};

    let jsonReponse = JSON.parse(interviewsData[0]?.meta);
    // console.log("preProcessInterviewData", jsonReponse.nontechnical)
    let nontech = jsonReponse.nontechnical;
    let tech = jsonReponse.technical;

    // console.log("preProcessInterviewData111111111", tech);

    Object.keys(nontech).forEach(category => {
        questionsDictionary[category] = nontech[category];
    });
    Object.entries(tech).forEach(([category, questionsList]) => {
        questionsDictionary[category] = questionsList;
    });
    Object.keys(questionsDictionary).forEach(category => {
        if (!questionsDict.hasOwnProperty(category)) {
            questionsDict[category] = [];
        }
        let newQList = [...questionsDictionary[category]];  // Create a copy of the original list
        for (let i = 0; i < noOfQuestions; i++) {
            let randomIndex = Math.floor(Math.random() * newQList.length);
            let currQ = newQList[randomIndex];
            questionsDict[category].push(currQ);
            newQList.splice(randomIndex, 1);
        }
    });
    for (let key in questionsDict) {
        totalQuestions += questionsDict[key].length;
    }
    return questionsDict;
    // console.log("interviewsData", questionsDict);
}

function preProcessInterviewData_Greetings(interviewsData) {
    let questionsDict = {};
    const questionsDictionary = {};
    let summary = JSON.parse(interviewsData[0]?.summary);
    let greetings = JSON.parse(summary?.greetings);
    let welcomemessage = greetings?.welcomeMessage;
    let closingMessage = greetings?.closingMessage;
    let greetings_object = {
        "welcome": welcomemessage,
        "closing": closingMessage
    }
    return greetings_object;
}


async function getNextQuestion(questionsDict) {
    let question = await getNextQuestionAsync(questionsDict);
    return question;
}

async function getNextQuestionAsync(questionsDict) {
    // console.log("questionsDict", questionsDict)
    var cats = "general";
    let q_index;
    if (!questionsDict) {
        return null;
    }
    q_index++;
    if (Object.keys(questionsDict).length === 0) {
        return { ques: "complete", category: "complete" };
    }

    // Get the first category's questions
    let firstCategoryKey = Object.keys(questionsDict)[0];
    let questions = questionsDict[firstCategoryKey];
    // console.log("firstCategoryKey", firstCategoryKey)
    if (firstCategoryKey == "coding") {
        cats = firstCategoryKey;
    }
    // Get the first question from this category
    let question = questions.shift();  // This also removes the question from the array

    // If there are no more questions in this category, delete the category from the dictionary
    if (questions.length === 0) {
        delete questionsDict[firstCategoryKey];
    }
    return { ques: question, category: cats } || null;  // Return the question, or null if it's undefined
}

export { preProcessInterviewData, getNextQuestion, preProcessInterviewData_Greetings, decryptData, getTotalQuestions, encrypt }